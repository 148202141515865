import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environment/environment";
import { AuthResponseModel } from "../_models/auth-response.model";
import { Observable, catchError, map, throwError } from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    toastrErrorHeader!: string;
    toastrErrorDesc!: string;

    constructor(
        private http: HttpClient
    ) { }

    /* ====================================================================================================================== */
    /* ====================================================== CLIENT ======================================================== */
    /* ====================================================================================================================== */

    // Client authentication
    clientAuth(token: string = ''): Promise<boolean> {
        if (token) {
            return new Promise((resolve, reject) => {
                resolve(true);
            });
        }

        let reqHeader = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
        });

        if (environment.clientAuthEnabled) {
            reqHeader = reqHeader.append("Authorization", `Basic ${environment.clientApiKey}`)
        }

        const body = new URLSearchParams();
        body.set('grant_type', 'client_credentials');

        return new Promise((resolve, reject) => {
            this.http.post<AuthResponseModel>(`${environment.domain}/oauth/token`, body.toString(), { headers: reqHeader }).subscribe({
                next: (response) => {
                    localStorage.setItem('auth', JSON.stringify(response));
                    resolve(true);
                },
                error: (err) => {
                    reject(err);
                }
            });
        });
    }

    // Get client access token
    getClientAccessToken(): string {
        const auth = localStorage.getItem('auth');
        if (auth) {
            const authJson = JSON.parse(auth);
            return authJson.access_token;
        }
        return '';
    }

    /* ==================================================================================================================== */
    /* ====================================================== USER ======================================================== */
    /* ==================================================================================================================== */

    // Get user access token
    getUserAccessToken(): string {
        const auth = localStorage.getItem('auth');
        if (auth) {
            const authJson = JSON.parse(auth);

            // Logged In
            if (authJson.refresh_token) {
                return authJson.access_token;
            }
        }
        return '';
    }

    // Get user role
    getUserRole(): string {
        const auth = localStorage.getItem('auth');
        if (auth) {
            const authJson = JSON.parse(auth);
            return authJson.role;
        }
        return '';
    }

    // Get user language access
    getUserAllowedLanguage(): number[] {
        const auth = localStorage.getItem('auth');
        // No auth in localstorage
        if (!auth) {
            return [];
        }

        const authJson = JSON.parse(auth);
        const allowedLanguages = authJson['allowed_cms_languages'];
        // Has allowed language in localstorage auth
        if (allowedLanguages) {
            return allowedLanguages.split(',').map(Number);
        }
        // No allowed language in localstorage auth (Meaning: has access to all language)
        return [1, 4852764, 4938438, 5017431, 4561158, 5028697, 5146990];
    }

    // Get OTP for login
    requestOtpForLogin(email: string, password: string): Observable<any> {
        const header = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        const body = {
            token: email,
            password: password
        };

        return this.http.post<any>(`${environment.domain}${environment.prefixPath}${environment.adminPath}${environment.authPath}/login/otp`, body, { headers: header }).pipe(
            map(res => {
                return res;
            })
        )
    }

    // User authentication
    userAuth(username: string, password: string, otp: string): Observable<any> {
        let reqHeader = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
        });

        if (environment.clientAuthEnabled) {
            reqHeader = reqHeader.append("Authorization", `Basic ${environment.clientApiKey}`)
        }

        const body = new URLSearchParams();
        body.set('grant_type', 'password');
        body.set('username', username);
        body.set('password', password);
        body.set('otp', otp);

        return this.http.post<any>(environment.domain + "/oauth/token", body.toString(), { headers: reqHeader }).pipe(
            catchError((error) => {
                return throwError(() => error); // Re-throw the error to propagate it further
            }),
            map(res => {
                return res;
            })
        );
    }

    // Logout user
    userLogout(): void {
        localStorage.removeItem('auth');
        window.location.reload();
    }

    // Invalidate user ccess token
    userServerLogout(): void {
        this.http.post<any>(`${environment.domain}${environment.prefixPath}${environment.adminPath}${environment.accountPath}/logout`, "");
        this.userLogout();
    }

    /* =========================================================================================================================== */
    /* ====================================================== ACTIVATION ========================================================= */
    /* =========================================================================================================================== */

    // Get OTP for account activation
    requestOtpForActivation(token: string, password: string): Observable<any> {
        let reqHeader = new HttpHeaders({
            'Content-Type': 'application/json'
        });

        const body = {
            token: token,
            password: password
        }

        return this.http.post<any>(`${environment.domain}${environment.prefixPath}${environment.adminPath}${environment.authPath}/activation/otp`, body, { headers: reqHeader }).pipe(
            catchError((error) => {
                return throwError(() => error); // Re-throw the error to propagate it further
            }),
            map(res => {
                return res;
            })
        );
    }

    // Submit account activation
    submitActivation(token: string, password: string, otp: string): Observable<any> {
        let reqHeader = new HttpHeaders({
            'Content-Type': 'application/json'
        });

        const body = {
            token: token,
            password: password,
            otp: otp
        }

        return this.http.post<any>(`${environment.domain}${environment.prefixPath}${environment.adminPath}${environment.authPath}/activation/submit`, body, { headers: reqHeader }).pipe(
            catchError((error) => {
                return throwError(() => error); // Re-throw the error to propagate it further
            }),
            map(res => {
                return res;
            })
        );
    }

    // Verify account activation (Link)
    verifyActivation(token: string): Observable<any> {
        const reqHeader = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        const body = {
            token: token
        }

        return this.http.post<any>(`${environment.domain}${environment.prefixPath}${environment.adminPath}${environment.authPath}/activation/verify`, body, { headers: reqHeader }).pipe(
            catchError((error) => {
                return throwError(() => error); // Re-throw the error to propagate it further
            }),
            map(res => {
                return res;
            })
        );
    }

    /* ============================================================================================================================== */
    /* ====================================================== RESET PASSWORD ======================================================== */
    /* ============================================================================================================================== */

    // Get OTP for reset password
    requestOtpForResetPassword(token: string, password: string): Observable<any> {
        const header = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        const body = {
            'token': token,
            'password': password
        };

        return this.http.post<any>(`${environment.domain}${environment.prefixPath}${environment.adminPath}${environment.authPath}/reset-password/otp`, body, { headers: header }).pipe(
            catchError((error) => {
                return throwError(() => error); // Re-throw the error to propagate it further
            }),
            map(res => {
                return res;
            })
        )
    }

    // Submit reset password request
    requestResetPassword(email: string): Observable<any> {
        const header = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        const body = {
            'username': email
        };
        return this.http.post<any>(`${environment.domain}${environment.prefixPath}${environment.adminPath}${environment.authPath}/reset-password/request`, body, { headers: header }).pipe(
            map(res => {
                return res;
            })
        )
    }

    // Submit reset password (New password)
    submitResetPassword(token: string, password: string, otp: string): Observable<any> {
        let reqHeader = new HttpHeaders({
            'Content-Type': 'application/json'
        });

        const body = {
            new_password: password,
            otp: otp,
            token: token
        }

        return this.http.post<any>(`${environment.domain}${environment.prefixPath}${environment.adminPath}${environment.authPath}/reset-password/submit`, body, { headers: reqHeader }).pipe(
            catchError((error) => {
                return throwError(() => error); // Re-throw the error to propagate it further
            }),
            map(res => {
                return res;
            })
        );
    }

    // Verify reset password (Link)
    verifyResetPassword(token: string): Observable<any> {
        const reqHeader = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        const body = {
            token: token
        }

        return this.http.post<any>(`${environment.domain}${environment.prefixPath}${environment.adminPath}${environment.authPath}/reset-password/verify`, body, { headers: reqHeader }).pipe(
            catchError((error) => {
                return throwError(() => error); // Re-throw the error to propagate it further
            }),
            map(res => {
                return res;
            })
        );
    }
}